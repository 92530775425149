import React from 'react';
import Grid from '@mui/material/Grid';
import '../page/smartenvironment.css'
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import { useNavigate } from "react-router-dom";
import Smc1 from '../resources/smc1.png'
import Smc2 from '../resources/smc2.webp'
import Iconsmc from '../resources/card_smc.png'
import Iconsmf from '../resources/card_smf.png'
import Iconsme from '../resources/card_sme.png'

const SmartEvironment = () => {
  let navigate = useNavigate();
  return (
    <>
      <h1 style={{ color: '#000000', fontWeight: '300', fontSize: 40, textAlign: 'center' }}>SmartEvironment</h1>
      <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" >
        {/* <Grid item xs={5}>
        <Paper
          elevation={10}
          className="border"
          style={{ height:310,width:400,marginLeft: 60, marginTop: 50, borderRadius: 30 }}
        >
          <img
            src={Smc1}
            style={{height:310,width:400}}
            className='picturesize'
            alt=""
          />
        </Paper>
      </Grid>
      <Grid item xs={7}>
        
        <h1 style={{ color: '#1C2B33', marginTop: 100, fontWeight: '500' }}>What is Smart Environment?</h1>
        <p style={{ color: '#465A69',marginRight: 120 }}>Cities that take into account the impact on the environment and climate change by using technology to help manage systematically, such as water management, climate care, waste management and disaster monitoring, as well as increase public participation in the conservation of natural resources</p>

      </Grid> */}

        <section className='content-con'>
          <div className='content-l'>
            <img
              src={Smc1}
              className='picturesize'
            />

          </div>
          <div className='content-r'>


            <h1 style={{ color: '#1C2B33', fontWeight: '500' }}>What is Smart Environment?</h1>
            <p style={{ color: '#465A69', fontSize: '1rem' }}>Cities that take into account the impact on the environment and climate change by using technology to help manage systematically, such as water management, climate care, waste management and disaster monitoring, as well as increase public participation in the conservation of natural resources</p>


          </div>
        </section>


        <Grid item xs={12}>
          <h1 style={{ color: '#000000', fontWeight: '300', fontSize: 30, textAlign: 'center' }}>Why  Smart Environment is better? </h1>
        </Grid>

        <section className='content-con'>
          <div className='content-l'>
            <img
              src={Smc2}
              className='picturesize'
            />
          <p style={{ color: '#465A69',  textAlign: 'center'}}> The cost of living and living will also be reduced by lower energy bills</p>
          </div>

          <div className='content-r'>
      
      
          <p style={{ color: '#465A69'}}>Energy saving is convenient</p>
          <p style={{ color: '#465A69' }}>More efficient transportation</p>
          <p style={{ color: '#465A69'}}> Older people can earn self-care income</p>
          <p style={{ color: '#465A69' }}>Safe </p>

     
      </div>
        </section>


        {/* <Grid item xs={5}>
          <Paper
            elevation={10}
            className="border"
            style={{ height: 310, width: 400, marginLeft: 60, marginTop: 50, borderRadius: 30 }}
          >
            <img
              src={Smc2}
              style={{ height: 310, width: 400 }}
              className='picturesize'
              alt=""
            />

          </Paper>
          <p style={{ color: '#465A69', marginLeft: 85 }}> The cost of living and living will also be reduced by lower energy bills</p>

        </Grid>
        <Grid item xs={7}
          container
          direction="column"
          justifyContent="space-evenly"
          style={{ marginTop: 50 }}

        >
          <p style={{ color: '#465A69', marginLeft: 20 }}>Energy saving is convenient</p>
          <p style={{ color: '#465A69', textAlign: "center" }}>More efficient transportation</p>
          <p style={{ color: '#465A69', marginLeft: 20 }}> Older people can earn self-care income</p>
          <p style={{ color: '#465A69', textAlign: "center" }}>Safe </p>
        </Grid> */}


        <p>-</p>

      </Grid>
    </>)


};

export default SmartEvironment;