import React from 'react';
import Grid from '@mui/material/Grid';
import '../page/smartenergy.css'
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import { useNavigate } from "react-router-dom";
import Iconsmc from '../resources/card_smc.png'
import Iconsmf from '../resources/card_smf.png'
import Iconsme from '../resources/card_sme.png'
import Sme1 from '../resources/sme1.webp'
import Sme2 from '../resources/sme2.webp'


const SmartEnergy = () => {
  let navigate = useNavigate();

    return (
      <>
    <h1 style={{ color: '#ff6600', fontWeight: '300', fontSize: 40, textAlign: 'center'}}>Smart Energy</h1>
   
    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" >
    <section className='content-con'>
    <div className='content-l'>
          <img
            src={Sme1}
            className='picturesize'
          />
     
      </div>
      <div className='content-r'>
      
      
        <h1 style={{ color: '#ff6600',fontWeight: '500' }}>What is Smart Energy?</h1>
        <p style={{ color: '#465A69',fontSize:'1rem'}}>Cities that can manage energy efficiently Balance the production and energy consumption in the area to create energy security and reduce energy dependence from the main power grid</p>

     
      </div>
      </section>
      <Grid item xs={12}>
        <h1 style={{ color: '#ff6600', fontWeight: '300', fontSize: 30, textAlign: 'center' }}>Why  Smart Energy is better?</h1>
      </Grid>

      <section className='content-con'>
    <div className='content-l'>
          <img
            src={Sme2}
            className='picturesize'
          />
     <p style={{ color: '#465A69',textAlign:'center'}}> Energy for city life, Energize smart living</p>
      </div>
      <div className='content-r'>
      
      
      <p style={{ color: '#465A69', }}>Integrating renewable energy into the electricity system</p>
        <p style={{ color: '#465A69',}}>Reducing maximum electricity demand</p>
        <p style={{ color: '#465A69' ,}}> Enhancing the efficiency of the management of the power grid</p>
        <p style={{ color: '#465A69' ,}}>Increases awareness of information generated in electrical systems</p>
     
      </div>
      </section>
      {/* <Grid item xs={5}>
        <Paper
          elevation={10}
          className="border"
          style={{ marginLeft: 60, marginTop: 50, borderRadius: 30,height:267,width:400}}
        >
          <img
            src={Sme2}
            className='picturesize'
            style={{height:267,width:400}}

            alt=""
          />

        </Paper>
        <p style={{ color: '#465A69',marginLeft: 110}}> Energy for city life, Energize smart living</p>

      </Grid>
      <Grid item xs={7} 
            container 
            direction="column"
            justifyContent="space-evenly"
            style={{ marginTop: 50}}

      >
        <p style={{ color: '#465A69',marginLeft:20 }}>Integrating renewable energy into the electricity system</p>
        <p style={{ color: '#465A69',textAlign:"center" }}>Reducing maximum electricity demand</p>
        <p style={{ color: '#465A69' ,marginLeft:20}}> Enhancing the efficiency of the management of the power grid</p>
        <p style={{ color: '#465A69' ,textAlign:"center"}}>Increases awareness of information generated in electrical systems</p>
      </Grid> */}
      
     
            <p>-</p>

    </Grid>
  </>
    )
  };
  
  export default SmartEnergy;