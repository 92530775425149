import React  from 'react';
import './App.css';
import Homepage from './page/homepage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Technology from './page/technology';
import AboutUs from './page/aboutus';
import Contact from './page/contact';
import Navbar from './component/Navbar.js';
import Footer from './component/Footer.js';
import SmartEnergy from './page/smartenergy';
import SmartEvironment from './page/smartenvironment';
import SmartFarm from './page/smartfarm';
import Blockchain from './page/blockchain';
import Res from './page/res';


function App() {



  return (

    <BrowserRouter>
      <Navbar />

      <Routes>

        <Route path="/" element={<Homepage />} />
    
        <Route path='res' element={<Res/>} />
        <Route path="/technology" element={<Technology />} />

        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/smartenergy" element={<SmartEnergy />} />
        <Route path="/smartevironment" element={<SmartEvironment />} />
        <Route path="/smartfarm" element={<SmartFarm />} />
        <Route path="/blockchain" element={<Blockchain />} />


      </Routes>
      <Footer />

    </BrowserRouter>

  );
}

export default App;
