import Grid from '@mui/material/Grid';
import Pic from '../resources/shot_one.png';
import Home from '../resources/homepage.jpg';
// import '../component/component.css'
import '../page/homepage.css'
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import Iconsmc from '../resources/card_smc.png'
import Iconsmf from '../resources/card_smf.png'
import Iconsme from '../resources/card_sme.png'

import Sis from '../resources/sis.jpg'
import Ingram from '../resources/ingram.jpg'
import Baywa from '../resources/Baywa.png'
import Synnex from '../resources/synnex.jpg'
import Tcrenewable from '../resources/tcrenew.png'
import Mi from '../resources/mi.png'
import Aec from '../resources/aec.png'

import React, { Component } from "react";
import Slider from 'react-slick';


const Homepage = () => {
    let navigate = useNavigate();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (<>
        <Grid container item justifyContent="flex-start" alignItems="flex-start">
            <section className='content-con'>
                <div className='content-l'>

                    <img
                        src={Pic}
                        className='picturesize'
                        alt=""
                    />

                </div>
                <div className='content-r'>
                    <h1 style={{ color: '#ff6600', marginTop: 100, fontWeight: '500' }}>GOAL : to be the top real digital communication provider in the country</h1>
                    <p style={{ color: '#465A69' }}>Digitalization move forward we make your. We put our clients first and adapt cutting-edge strategies and technologies by ADC microsystems</p>
                </div>

            </section>

            <Grid item xs={12} style={{ marginTop: 20 }}>
                <div>
                    <Slider {...settings}>
                        <div>
                            <img
                                src={Sis}
                                className='picturepartnersize'
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src={Ingram}
                                className='picturepartnersize'
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src={Baywa}
                                className='picturepartnersize'
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src={Synnex}
                                className='picturepartnersize'
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src={Tcrenewable}
                                className='picturepartnersize'
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src={Mi}
                                className='picturepartnersize'
                                alt=""
                            />
                        </div>

                    </Slider>
                </div>
            </Grid>


            <div className='content-con'>
                <div className='content-r'>
                    <h1 style={{ color: '#ff6600', marginTop: 100, fontWeight: '500' }}>Why? Because we want to make a difference in peoples' lives</h1>
                </div>
                <div className='content-l'>
                    <img
                        src={Home}
                        className='picturesize'
                    />
                </div>
            </div>



            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={12}
                style={{ borderTop: 100 }}
            >
                <h1 style={{ color: '#1C2B33', fontWeight: '400', textAlign: 'center', fontSize: '2.5rem' }}>You can see the project that we have</h1>
            </Grid>

        <Box sx={{ flexGrow: 1 }} >
            <Grid
                item
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                columns={{ xs: 2, sm: 8, md: 12 }}
                spacing={{ xs: 2, md: 3 }}
            >
                <Grid item >
                <Paper elevation={5} style={{ borderRadius: 30}} className='card' onClick={() => { navigate('/smartenergy') }}>
                            <Card className='card' style={{ textAlign: 'center', display: 'inline-block', borderRadius: 30 }}>
                                <img
                                    src={Iconsme}
                                    className='iconsize'
                                    style={{ marginTop: 20 }}
                                    alt=""
                                />
                                <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Smart Energy</h1>
                            </Card>
                        </Paper>
                </Grid>
                <Grid item>
                <Paper elevation={5} style={{ borderRadius: 30 }} className='card' onClick={() => { navigate('/smartfarm') }}>
                            <Card className='card' style={{ textAlign: 'center', display: 'inline-block', borderRadius: 30 }}>

                                <img
                                    src={Iconsmf}
                                    className='iconsize'
                                    style={{ marginTop: 20 }}
                                    alt=""
                                />
                                <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Smart Farm</h1>
                            </Card>
                        </Paper>
                </Grid>
                <Grid item>
                <Paper elevation={5} style={{ borderRadius: 30 }} className='card' onClick={() => { navigate('/smartevironment') }}>
                            <Card className='card' style={{ textAlign: 'center', display: 'inline-block', borderRadius: 30 }}>
                                <img
                                    src={Iconsmc}
                                    className='iconsize'
                                    style={{ marginTop: 20 }}
                                    alt=""
                                />
                                <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Smart Environment</h1>
                            </Card>
                        </Paper>
                </Grid>


            </Grid>
            <br></br>
        </Box>
            
            {/* <Box  sx={{ flexGrow: 1 }}  >
                <Grid container 
                        spacing={{ xs: 2, md: 3 }} 
                        columns={{ xs: 2, sm: 8, md: 12 }} >
                    <Grid item xs={4} sm={4} md={4}  >
                        <Paper elevation={5} style={{ borderRadius: 30}} className='card' onClick={() => { navigate('/smartenergy') }}>
                            <Card className='card' style={{ textAlign: 'center', display: 'inline-block', borderRadius: 30 }}>
                                <img
                                    src={Iconsme}
                                    className='iconsize'
                                    style={{ marginTop: 20 }}
                                    alt=""
                                />
                                <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Smart Energy</h1>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <Paper elevation={5} style={{ borderRadius: 30 }} className='card' onClick={() => { navigate('/smartfarm') }}>
                            <Card className='card' style={{ textAlign: 'center', display: 'inline-block', borderRadius: 30 }}>

                                <img
                                    src={Iconsmf}
                                    className='iconsize'
                                    style={{ marginTop: 20 }}
                                    alt=""
                                />
                                <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Smart Farm</h1>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <Paper elevation={5} style={{ borderRadius: 30 }} className='card' onClick={() => { navigate('/smartevironment') }}>
                            <Card className='card' style={{ textAlign: 'center', display: 'inline-block', borderRadius: 30 }}>
                                <img
                                    src={Iconsmc}
                                    className='iconsize'
                                    style={{ marginTop: 20 }}
                                    alt=""
                                />
                                <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Smart Environment</h1>
                            </Card>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </Box> */}

        </Grid>
    </>
    )
}



export default Homepage
