import { Button } from '@mui/material';
import React, { useState } from 'react';
import Icon from '../resources/logo_adcm.png';
import './component.css';
import { useNavigate } from "react-router-dom";
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { FiX, FiMenu } from "react-icons/fi";
import { click } from '@testing-library/user-event/dist/click';
// import { IconName } from "react-icons/fi";
const styledButton =
{
  color: "white", '&:hover': {
    backgroundColor: '#ff6600',
    // color:'#ff6600'
  },
}


  ;

function Navbar() {
  let navigate = useNavigate();

  const [click, setClick] = useState(false);
  console.log(click)
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    // <>
    // <div className ='Pos_nav' style={{height:'10vh'}}>

    //     <div className="logo_text"  onClick={() => {navigate('/')}} style={{cursor:'pointer'}}>
    //     <img
    //             src={Icon}
    //             className='logo'
    //             alt=''
    //         />
    //            <div style={{color : '#fff'}}>ADC MICROSYSTEMS</div>
    //     </div>
    //        <div >
    //          {/* <Button sx={styledButton} variant="text">ADCm</Button> */}
    //             <Button onClick={() => {navigate('/technology')}}sx={styledButton} >Technology</Button>
    //             <Button onClick={() => {navigate('/blockchain')}}sx={styledButton} >Blockchain</Button>
    //             <Button onClick={() => {window.open('https://www.facebook.com/adcmicro')}}sx={styledButton} >News/Blog</Button>
    //             <Button onClick={() => {navigate('/aboutus')}}sx={styledButton} >About US</Button>
    //             <Button onClick={() => {navigate('/contact');}}sx={styledButton} >Contact</Button>              
    //        </div>
    //        <div className='mobile-menu'>
    //         {click? 
    //         (<FiX/>)
    //         :(<FiMenu/>)}
    //        </div>
    // </div>
    // </>
    <div className='header'>
      <div className='container'>
        <div className='header-con'>
          <div className='logo-container'>
            <a href='/'>
              <img
                src={Icon}
                className='logo'
              />
              ADC MICROSYSTEMS</a>
          </div>
          <ul className={click ? "menu active" : "menu"} >
          <li className='menu-link' onClick={closeMobileMenu}>
              <Button onClick={() => { navigate('/res') }} sx={styledButton} >Res</Button>
            </li>
            <li className='menu-link' onClick={closeMobileMenu}>
              <Button onClick={() => { navigate('/technology') }} sx={styledButton} >Technology</Button>
            </li>
            <li className='menu-link' onClick={closeMobileMenu} sx={styledButton}>
              <Button onClick={() => { navigate('/blockchain') }} sx={styledButton} >Blockchain</Button>
            </li>
            <li className='menu-link' onClick={closeMobileMenu} sx={styledButton}>
              <Button onClick={() => { window.open('https://www.facebook.com/adcmicro') }} sx={styledButton} >News/Blog</Button>

            </li>
            <li className='menu-link' onClick={closeMobileMenu} sx={styledButton}>
              <Button onClick={() => { navigate('/aboutus') }} sx={styledButton} >About US</Button>

            </li>
            <li className='menu-link' onClick={closeMobileMenu} sx={styledButton}>
              <Button onClick={() => { navigate('/contact'); }} sx={styledButton} >Contact</Button>

            </li>
          </ul>
          <div className='mobile-menu' onClick={handleClick} sx={styledButton}>
            {click ?
              (<FiX />)
              : (<FiMenu />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;