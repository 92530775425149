import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import '../component/component.css';
import Man from '../resources/avatarman.png';
import Woman from '../resources/avatarwoman.png';
import data from '../name.json'
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
const AboutUs = () => {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (

    <><p style={{ textAlign: 'center', fontWeight: '600', fontSize: 30 }}>CEO</p>
      <Grid style={{ fontSize: 30 }}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          xs={12}
        >
          <Card style={{ height: 200, width: 200, backgroundColor: '#E7E7E7', alignItems: 'center' }}>
            <img
              src={data.Picture}
              style={{ height: 200, width: 200 }}
            />
          </Card>
          <p style={{ textAlign: 'center' }}>Abhisit Ngamnate</p>
          <p style={{ textAlign: 'center', color: '#9B9B9B' }}>Chief executive officer</p>


        </Grid>

        <p style={{ textAlign: 'left', fontWeight: '600' }}>Employee</p><p></p>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {Array.from(data).map((data, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Card style={{ height: 200, width: 200, backgroundColor: '#ffffff', marginLeft: 'auto', marginRight: 'auto' }}>
                  <img
                    src={data.Picture}
                    style={{ height: 200, width: 200 }}
                  />
                </Card>
                <p style={{ textAlign: 'center' }}>{data.Name}</p>
                <p style={{ textAlign: 'center', color: '#9B9B9B' }}>{data.position}</p>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>

    </>)
};


export default AboutUs;