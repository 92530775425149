import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MapContainer, TileLayer } from 'react-leaflet'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import 'leaflet/dist/leaflet.css'
import '../page/contact.css'
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import emailjs from '@emailjs/browser';
import Card from '@mui/material/Card';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});



L.Marker.prototype.options.icon = DefaultIcon;

const Contact = () => {

  const position = [16.739474, 100.194299]
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6jbj1ca', 'template_v5z2rgz', e.target, 'klrHrytam1oLN3kkQ')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  };
  return (
    <Grid container spacing={2} >
      <Grid item xs={12} md={4} style={{ justifyContent: "center", display: "flex", flexWrap: "wrap", flexDirection: "column", alignItems: "center", textAlign: "left" }}>
        <div style={{ width: "70%" }}>
          <h1>ADCM</h1>
          <p>
            36/10 หมู่ที่ 10 ตำบลวัดจันทร์ อำเภอเมืองพิษณุโลก จ.พิษณุโลก
          </p>
          <p>
            info@adcm.co.th
          </p>
          <p>
            0812345678
          </p>
        </div>
      </Grid>
      <section className='container'>

        <div className='content'>
          <h1 style={{ textAlign: "center", color: '#000000' }}>Contact us</h1>
          <form
            onSubmit={sendEmail}
            sx={{
              '& > :not(style)': { m: 2, width: '300px' },
            }}
            noValidate
            autoComplete="off"
          >

            <TextField type="text" id="standard-basic" color="warning" name="name" label="Name" variant="outlined" />
            <TextField type="email" style={{ marginLeft: 10 }} id="standard-basic" color="warning" name="email" label="E-mail" variant="outlined" />
            <p></p>
            <TextField type="tel" id="standard-basic" color="warning" name="phone" label="Phonenumber" variant="outlined" />
            <TextField type="text" style={{ marginLeft: 10 }} id="standard-basic" color="warning" name="address" label="Address" variant="outlined" />
            <p></p>
            <TextField type="text" id="standard-basic" color="warning" name="subject" label="Subject" variant="outlined" />
            <p></p>

            <TextField
              id="standard-multiline-static"
              label="Type your message here..."
              multiline
              style={{ width: '100%' }}
              color="warning"
              variant="outlined"
              name="message"
            />
            <p></p>
            <Button type="submit" value="Send" variant="contained" style={{ backgroundColor: '#ff6600', color: '#ffffff', width: '15%', textAlign: "center" }}>Send</Button>

          </form>
        </div>
      </section>
      <Grid item xs={12}>
        <MapContainer center={position} zoom={16} scrollWheelZoom={false} style={{ height: '70vh' }} >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              36/10 หมู่ที่ 10 ตำบลวัดจันทร์ อำเภอเมืองพิษณุโลก จ.พิษณุโลก 65000
        </Popup>
          </Marker>
        </MapContainer>
      </Grid>

    </Grid>


  )
}

export default Contact;