
import React from 'react';

import Grid from '@mui/material/Grid';
import Bct from '../resources/BCT.jpg'
import Mss from '../resources/mss.webp'
import Dc from '../resources/dc.webp'
// import '../component/component.css'
import '../page/technology.css'
import Paper from '@mui/material/Paper';

const Technology = () => {
  return (<>
    <h1 style={{ color: '#ff6600', fontWeight: '300', textAlign: 'center', fontSize: 40 }}>We Make Your Digitalization Move Forward</h1>


    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        <section className='content-con'>
          <div className='content-l'>
            {/* <Card className='picturesize'> */}
            <img
              src={Mss}
              className='picturesize'
              alt=""
            />
            <p style={{ color: '#98FB98', textAlign: 'center' }}>Microgrid Service Solutions</p>

            {/* </Card> */}
          </div>

          <div className='content-r'>
            <p style={{ color: '#465A69', textAlign: 'center' }}>Microgrid is an electrical power system that uses local and distributed energy sources to meet local electricity needs and can operate independently of the national electricity grid.</p>
          </div>

        </section>
      </Grid>
      <Grid item xs={12}>
        <section className='content-con'>


          <div className='content-r'>
            <p style={{ color: '#5a5a5a', marginTop: 100, textAlign: 'center', marginLeft: 50 }}>The communication that occurs in our day-to-day life is in the form of signals. These signals, such as sound signals, generally, are analog in nature</p>
          </div>
          <div className='content-l'>

            <img
              src={Dc}
              className='picturesize'
              alt=""
            />
            <p style={{ color: '#98FB98', textAlign: 'center' }}>Digital Communication</p>


          </div>

        </section>
      </Grid>
      <Grid item xs={12}>
        <div className='content-con'
        >
          <div className='content-l'>

            <img
              src={Bct}
              className='picturesize'
              alt=""
            />
            <p style={{ color: '#98FB98', textAlign: 'center' }}>Blockchain Technology</p>


          </div>
          <div className='content-r'>
            <p style={{ color: '#5a5a5a', marginTop: 100, textAlign: 'center'}}>It is a decentralized processing and storage technology, a data logging model that uses the Cryptography principle in conjunction with the Consensus mechanism.</p>
          </div>

        </div>
      </Grid>


    </Grid>
  </>)
};

export default Technology;