import React from 'react';
import Grid from '@mui/material/Grid';
import '../page/smartfarm.css'
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import { useNavigate } from "react-router-dom";
import Smf1 from '../resources/smf1.jpg'
import Smf2 from '../resources/smf2.webp'
import Iconsmc from '../resources/card_smc.png'
import Iconsmf from '../resources/card_smf.png'
import Iconsme from '../resources/card_sme.png'


const SmartFarm = () => {
  let navigate = useNavigate();

    return (
      <>
       <h1 style={{ color: '#98FB98', fontWeight: '300', fontSize: 40, textAlign: 'center'}}>Smart Farm</h1>
    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" >
      {/* <Grid item xs={5}>
        <Paper
          elevation={10}
          className="border"
          style={{height:291,width:350, marginLeft: 60, marginTop: 50, borderRadius: 30 }}
        >
          <img
            src={Smf1}
            className='picturesize'
            style={{height:291,width:350}}
            alt=""
          />
          <p>xs=4</p>
        </Paper>
      </Grid>
      <Grid item xs={7}>
        <p>xs=8</p>
        <h1 style={{ color: '#98FB98', marginTop: 100, fontWeight: '500' }}>What is Smart Farm?</h1>
        <p style={{ color: '#465A69', marginRight: 110 }}>Smart farming is a management concept focused on providing the agricultural industry with the infrastructure to leverage advanced technology – including big data, the cloud and the internet of things (IoT) – for tracking, monitoring, automating and analyzing operations. Also known as precision agriculture, smart farming is software-managed and sensor-monitored. Smart farming is growing in importance due to the combination of the expanding global population, the increasing demand for higher crop yield, the need to use natural resources efficiently, the rising use and sophistication of information and communication technology and the increasing need for climate-smart agriculture</p>

      </Grid> */}

      <section className='content-con'>
    <div className='content-l'>
          <img
            src={Smf1}
            className='picturesize'
          />
     
      </div>
      <div className='content-r'>
      
      
      <h1 style={{ color: '#98FB98', fontWeight: '500' }}>What is Smart Farm?</h1>
        <p style={{ color: '#465A69',fontSize:'1rem'}}>Smart farming is a management concept focused on providing the agricultural industry with the infrastructure to leverage advanced technology – including big data, the cloud and the internet of things (IoT) – for tracking, monitoring, automating and analyzing operations. Also known as precision agriculture, smart farming is software-managed and sensor-monitored. Smart farming is growing in importance due to the combination of the expanding global population, the increasing demand for higher crop yield, the need to use natural resources efficiently, the rising use and sophistication of information and communication technology and the increasing need for climate-smart agriculture</p>

     
      </div>
      </section>
      <Grid item xs={12}>
        <h1 style={{ color: '#98FB98', fontWeight: '300', fontSize: 30, textAlign: 'center' }}>Why  Smartfarm is better?</h1>
      </Grid>
      <section className='content-con'>
    <div className='content-l'>
          <img
            src={Smf2}
            className='picturesize'
          />
      <p style={{ color: '#465A69',textAlign:'center'}}>Increased accuracy and ease of measuring water quality 24 hours a day</p>
      </div>
      <div className='content-r'>
      
      <p style={{ color: '#465A69'}}>Reduce risk</p>
        <p style={{ color: '#465A69' }}>Comfort and convenience</p>
        <p style={{ color: '#465A69' }}>Cost savings</p>
        <p style={{ color: '#465A69' }}>Increase productivity</p>

     
      </div>
      </section>
      {/* <Grid item xs={5}>
        <Paper
          elevation={10}
          className="border"
          style={{height:290,width:400, marginLeft: 60, marginTop: 50, borderRadius: 30 }}
        >
          <img
            src={Smf2}
            style={{height:290,width:400}}
            className='picturesize'
            alt=""
          />

        </Paper>
        <p style={{ color: '#465A69',marginLeft: 75}}>Increased accuracy and ease of measuring water quality 24 hours a day</p>

      </Grid>
      <Grid item xs={7} 
            container
            direction="column"
            justifyContent="space-evenly"
            style={{ marginTop: 50}}

      >
        <p style={{ color: '#465A69',marginLeft:120}}>Reduce risk</p>
        <p style={{ color: '#465A69',marginLeft:280 }}>Comfort and convenience</p>
        <p style={{ color: '#465A69' ,marginLeft:120}}>Cost savings</p>
        <p style={{ color: '#465A69' ,marginLeft:280}}>Increase productivity</p>
      </Grid> */}
      
      {/* <Grid item xs={12}>
        <h1 style={{ color: '#000000', fontWeight: '300', fontSize: 40, textAlign: 'center'}}>Our Solutions</h1>
      </Grid>





      <Grid
            item
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                xs={12}
                        >    
               <Paper elevation={5} style={{borderRadius: 30}} className='card' onClick={() => {navigate('/')}}>
                <Card  className='card' style={{ textAlign: 'center',display: 'inline-block' ,borderRadius: 30}}>
                    <img
                        src={Iconsmc}
                        className='iconsize'
                        style={{ marginTop: 20}}
                        alt=""
                    />
                    <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Project A</h1>
                </Card>
                </Paper>
               <Paper elevation={5} style={{borderRadius: 30}} className='card' onClick={() => {navigate('/')}}>
                <Card  className='card' style={{ textAlign: 'center',display: 'inline-block',borderRadius: 30 }}>
                <img
                        src={Iconsme}
                        className='iconsize'
                        style={{ marginTop: 20}}
                        alt=""
                    />
                    <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Project B</h1>
                </Card>
                </Paper>
               <Paper elevation={5} style={{borderRadius: 30}} className='card' onClick={() => {navigate('/')}}>
                <Card  className='card' style={{ textAlign: 'center',display: 'inline-block',borderRadius: 30}}>
                    
                    <img
                        src={Iconsmf}
                        className='iconsize'
                        style={{ marginTop: 20}}
                        alt=""
                    />
                    <h1 style={{ color: '#465A69', fontWeight: '300', textAlign: 'center', fontSize: 30 }}>Project C</h1>
                </Card>
                </Paper>


            </Grid>*/}
            <p>-</p> 

    </Grid>
      </>
    )
  };
  
  export default SmartFarm;