import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { Container, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";

function Footer() {
  let navigate = useNavigate();
  return (
    <Container maxWidth={false} sx={{ display: "flex", background: '#5a5a5a ', width: '100%', bottom: 0, color: 'white', wordBreak: "break-word" }}>
      <Grid container sx={theme => ({
        padding: 5,
        [theme.breakpoints.down(900)]: {
          rowGap: '1em'
        }
      })}>
        <Grid item xs={12} md={4} lg={4} sm={12}>
          <Grid container spacing={2} >
            <h1>Follow Us</h1>
            <Grid item xs={12}>
              <FacebookIcon></FacebookIcon>
              <span style={{ marginLeft: 10, fontSize: 14 }}>ADC Microsystems
              </span>
            </Grid>
            <Grid item xs={12}>
              <EmailIcon></EmailIcon>
              <span style={{ marginLeft: 10, fontSize: 14 }}>info@adcm.co.th</span>

            </Grid>
            <Grid item xs={12}>
              <HomeWorkIcon ></HomeWorkIcon>
              <span style={{ marginLeft: 10, fontSize: 14 }}>36/10 หมู่ที่ 10 ตำบลวัดจันทร์ อำเภอเมืองพิษณุโลก จ.พิษณุโลก 65000</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={theme => ({
          [theme.breakpoints.up(899)]: {
            borderLeft: 1,
            paddingLeft: 5
          }
        })} xs={12} md={4} lg={4} sm={12}>
          <Grid container spacing={2}>
            <h1>What we build</h1>
            <Grid item xs={12} onClick={() => { navigate('/smartenergy') }} style={{ cursor: 'pointer' }}>
              Smart Energy
            </Grid>
            <Grid item xs={12} onClick={() => { navigate('/smartfarm') }} style={{ cursor: 'pointer' }}>
              Smart Farm
            </Grid>
            <Grid item xs={12} onClick={() => { navigate('/smartevironment') }} style={{ cursor: 'pointer' }}>
              Smart Environment
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sm={12}>
          <Grid container spacing={2}>
            <h1>Company</h1>
            <Grid item xs={12} onClick={() => { navigate('/technology') }} style={{ cursor: 'pointer' }}>
              Technology
            </Grid>
            <Grid item xs={12} onClick={() => { navigate('/blockchain') }} style={{ cursor: 'pointer' }}>
              Blockchain
            </Grid>
            <Grid item xs={12} onClick={() => { window.open('https://www.facebook.com/adcmicro') }} style={{ cursor: 'pointer' }}>
              News
            </Grid>
            <Grid item xs={12} onClick={() => { navigate('/aboutus') }} style={{ cursor: 'pointer' }}>
              About Us
            </Grid>
            <Grid item xs={12} onClick={() => { navigate('/contact') }} style={{ cursor: 'pointer' }}>
              Contact
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;