import React from 'react';
import '../page/blockchain.css'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Blockchain1 from '../resources/Blockchain.jpg';
import Blockchain2 from '../resources/blockchain2.png';
import Blockchain3 from '../resources/blockchain3.png'

const Blockchain = () => {
  return (
    <><h1 style={{ color: '#000000', fontWeight: '300', fontSize: 40, textAlign: 'center' }}>Blockchain Page</h1>
   
       <Grid item xs={12}>
        <section className='content-con' style={{justifyContent: 'center'}}>
          <div className='banner'>
            <img
              src={Blockchain1}
              className='picturesize'
              alt=""
            />
          
          </div>

       

        </section>
      </Grid>
      <div style={{backgroundColor: '#F8F8F8'}}>
      <h1 style={{ color: '#000000', fontSize: 40, textAlign: 'center', fontWeight: '600' }}>Why Blockchain is better?</h1>
      <section className='content-con'>
        
                <div className='content-l'>
                  
                    <img
                        src={Blockchain2}
                        className='picturesize'
                        alt=""
                    />
                   
                </div>
                <div className='content-r'>
                <h1 style={{ color: '#000000',fontWeight: '500'}}>
              Ownership
            </h1>
            <p style={{ color: '#465A69', marginLeft: 50,}}>It allows us to actually keep assets or money with ourselves. When transferring, transfer it in a Peer-to-Peer manner without having to ask anyone.</p>
            <h1 style={{ color: '#000000',fontWeight: '500' }}>
            Transparency & Immutability
            </h1>
            <p style={{ color: '#465A69', marginLeft: 50 }}>There is no company or anyone who can secretly edit the past information.</p>
            <h1 style={{ color: '#000000',fontWeight: '500' }}>
              Borderless
            </h1>
            <p style={{ color: '#465A69', marginLeft: 50 }}>
              no matter where we are We only have internet We can use the system at any time.</p>
                </div>
                
            </section>
            </div>
            <section className='content-con'>
                
                <div className='content-r'>
                <h1 style={{ color: '#000000',fontWeight: '500' }}>
              Open & Neutral
            </h1>
            <p style={{ color: '#465A69', marginLeft: 50 }}>Everyone has the right to use them equally. There is no bias or double standards.</p>

            <h1 style={{ color: '#000000',fontWeight: '500' }}>
              Security
            </h1>
            <p style={{ color: '#465A69', marginLeft: 50 }}>We distribute accounts all over the world. To fix it, countless computers were hacked at the same time.</p>

            <h1 style={{ color: '#000000',fontWeight: '500' }}>
              Cut Cost
            </h1>
            <p style={{ color: '#465A69', marginLeft: 50 }}>
              When there is no intermediary business that comes to eat profit The cost of the transaction is definitely lower.</p>

                </div>
                <div className='content-l'>
                    
                    <img
                        src={Blockchain3}
                        className='picturesize'
                        alt=""
                    />
                    
                </div>
                
            </section>

     

    </>

  )
};

export default Blockchain;


